<template>
  <v-expansion-panel-header
    :class="
    [
      'action-button',
      $vuetify.breakpoint.mobile ? 'd-block auto-margin' : ''
    ]"
    hide-actions
    v-if="content.length > 0 && !withoutDescription" >
    <template v-slot:default="open">
      <ver-detalles-button 
      :is-opened="open.open"
      v-show="intersecting"/>
    </template>
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'AccordionDetallesButton',
  components: {
    VerDetallesButton: () => import('@/views/components/VerDetallesButton.vue'),
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    withoutDescription: {
      type: Boolean,
      default: false
    },
    intersecting: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>